<template>
  <div class="col-12">
    <!-- <Breadcrumb :home="home" :model="breadcrumbitems" /> -->
    <TabMenu :model="items" :activeIndex="activeIndex" />
    <router-view />
  </div>
</template>

<script>
import { servidorStore } from '@/stores/servidor'

export default {
  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbitems: [
        { label: 'Matrículas', to: { name: 'matriculas' } },
        { label: 'Margem' },
      ],
      activeIndex: 0,
      tabIndex: {
        margem: 0,
        consignacoes: 1,
        renegociacoes: 2,
        'compra-divida-lista': 3,
        reserva_cartao: 4,
        historico_margem: 5,
        propostas: 6,
      },
      items: [
        { label: 'Margem', to: { name: 'margem' } },
        {
          label: 'Consignações',
          to: { name: 'consignacoes' },
        },
        {
          label: 'Renegociações',
          to: { name: 'renegociacoes' },
        },
        { label: 'Portabilidades', to: { name: 'compra-divida-lista' } },
        { label: 'Reserva de Cartão', to: { name: 'reserva_cartao' } },
        { label: 'Histórico de Margem', to: { name: 'historico_margem' } },
        { label: 'Propostas', to: { name: 'propostas' } },
      ],
    }
  },

  mounted() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },

  updated() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },
}
</script>

<style lang="scss" scoped>
::v-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

::v-deep .p-highlight .router-link-exact-active {
  color: var(--primary-color) !important;
}

::v-deep .p-menuitem-link {
  height: 103%;
}
</style>
